import React, { Component, Fragment } from 'react';
import { graphql } from 'gatsby';
import { withTranslation, WithTranslation } from 'gatsby-plugin-react-i18next';

/** Components */
import Seo from '@components/seo';
import MainContainer from '@components/main.container';

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

class LegalTermsComponent extends Component<WithTranslation> {
  constructor(props: Readonly<WithTranslation>) {
    super(props);
  }

  render(): JSX.Element {
    const { t } = this.props;

    return (
      <Fragment>
        <Seo description={t('seo.credits')} />
        <MainContainer>
          <div className="container-xxl pt-4 pb-4 text-center">
            <div className="row text-center">
              <h1 className="text-uppercase fw-bold mt-5 pt-5 mb-3">
                {t('legalTerms.siteEditor')}
              </h1>
              <div>Iofate</div>
              <div>SAS au capital de 1 000€</div>
              <div>SIREN : 8978 474 630 0014</div>
              <div>Iofate – 1938 rue de Sermezy – 69220 Charentay</div>
              <div>Directeur de la publication : Johann Servoire</div>
              <div>Contact : whalinvest[@]gmail.com</div>

              <h1 className="text-uppercase fw-bold mt-5 pt-5 mb-3">
                {t('legalTerms.hosting')}
              </h1>
              <div>L’hébergement du site est réalisé par la société OVH :</div>
              <div>OVH SAS</div>
              <div>2, Rue Kellermann</div>
              <div>59100 Roubaix</div>
              <div>France</div>
              <div>SIRET 42476141900045</div>

              <div>
                <h1 className="text-uppercase fw-bold mt-5 pt-5 mb-3">
                  {t('legalTerms.userRights')}
                </h1>
                <div className="row justify-content-center">
                  <div className="col-11 col-md-4">
                    <div className="text-start">
                      Conformément à la RGPD, Iofate s&apos;engage à ce que vos
                      données soient traitées en accord avec le règlement mais
                      aussi de manière sécurisée. Vous pouvez ainsi demander :
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <ul className="text-start col-11 col-md-3">
                    <li>La suppression de vos données personnelles</li>
                    <li>La correction de vos données personnelles</li>
                    <li>L&apos;accès à vos données personnelles</li>
                  </ul>
                </div>
              </div>

              <h1 className="text-uppercase fw-bold mt-5 pt-5 mb-3">
                {t('legalTerms.credits')}
              </h1>
              <a
                className="text-center text-body"
                href="https://www.flaticon.com/free-icons/shrimp"
                title="Shrimp icons"
              >
                Shrimp icons created by Good Ware - Flaticon
              </a>
              <a
                className="text-center text-body"
                href="https://www.flaticon.com/free-icons/shark"
                title="shark icons"
              >
                Shark icons created by max.icons - Flaticon
              </a>
              <a
                className="text-center text-body mb-5"
                href="https://www.flaticon.com/free-icons/whale"
                title="whale icons"
              >
                Whale icons created by BZZRINCANTATION - Flaticon
              </a>
            </div>
          </div>
        </MainContainer>
      </Fragment>
    );
  }
}

const LegalTerms = withTranslation()(LegalTermsComponent);

export default LegalTerms;
